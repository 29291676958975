import {
    ActivePolicyRangeDateFormat,
    FormatDistanceToNow,
    ISODateFormat,
    ISODateFormatUS,
    ISODateFormatVariable,
    ISODateFormatWithTime,
    ISODateFormatWithTimeZone,
    ISODateSpelledWithTimeZone,
    PublishedPostDateFormat,
    ShortMonthDayYearFormat,
} from './date-fns.pipe';
import { CallbackPipe, SortByKey, SSN } from './generic.pipe';
import { CoverageBreakdownPipe, DBNumberToCurrency } from './number-transforms.pipe';
import { PhonePipe } from './phone.pipe';
import { SafePipe } from './safe.pipe';
import { CamelCase, CapitalCase, HumanReadable, SentenceCase } from './string-manipulations.pipe';

export const pipes = [
    ActivePolicyRangeDateFormat,
    CallbackPipe,
    CamelCase,
    CapitalCase,
    CoverageBreakdownPipe,
    DBNumberToCurrency,
    FormatDistanceToNow,
    HumanReadable,
    PhonePipe,
    ISODateFormat,
    ISODateFormatUS,
    ISODateFormatWithTime,
    ISODateFormatWithTimeZone,
    ISODateSpelledWithTimeZone,
    PublishedPostDateFormat,
    ISODateFormatVariable,
    SafePipe,
    SentenceCase,
    ShortMonthDayYearFormat,
    SortByKey,
    SSN,
];

export * from './date-fns.pipe';
export * from './generic.pipe';
export * from './number-transforms.pipe';
export * from './phone.pipe';
export * from './safe.pipe';
export * from './string-manipulations.pipe';
