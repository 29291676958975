import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UpdateModalComponent } from '@common/components/update-modal/update-modal.component';
import { ToastBodySwitch } from '@common/models';
import { EnvService } from '@common/services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class UpdateService {
    constructor(
        private updates: SwUpdate,
        private envService: EnvService,
        private ngbModalService: NgbModal,
        private toastService: ToastService
    ) {
        this.updates.versionUpdates.subscribe((evt) => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    console.log(`Current app version: ${evt.currentVersion.hash}`);
                    console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                    if (this.envService.isProd) {
                        this.toastService.show({
                            header: 'A new version of Alpha147.com is ready!',
                            // body: 'Reload to use this new version',
                            bodySwitch: ToastBodySwitch.reloadSite,
                            options: {
                                autohide: false,
                                headerClasses: 'bg-primary text-white',
                                bodyClasses: 'text-primary',
                                noCloseButton: true,
                            },
                        });
                        return;
                    }
                    this._warnVersionReadyLowerEnvs();
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.log(
                        `Failed to install app version '${evt.version.hash}': ${evt.error}`
                    );
                    break;
            }
        });
    }

    _warnVersionReadyLowerEnvs() {
        const {
            // componentInstance,
        }: {
            componentInstance: UpdateModalComponent;
            result: Promise<unknown>;
        } = this.ngbModalService.open(UpdateModalComponent, {
            size: 'lg',
            centered: false,
        });

        // componentInstance.title = accountUserActiveAlert.alertText;
    }
}
