import { Injectable } from '@angular/core';
import { UtmLocalStorage } from '@backend-types/utm';
import { snakeCase } from 'change-case';
import compare from 'just-compare';

import { LogService } from './log.service';
import { SplitTestService } from './split-test.service';
import { UtilityService } from './utility.service';

@Injectable()
export class UtmService {
    constructor(
        private utilityService: UtilityService,
        private splitTestService: SplitTestService,
        private logService: LogService
    ) {}

    get utm(): UtmLocalStorage | null {
        const utmLocalStorage = this.utilityService.getStoredObject<UtmLocalStorage>('utm_storage');

        let splitTest: string | null = null;
        let splitTestValue: string | null = null;

        if (this.splitTestService.splitTest) {
            splitTest = snakeCase(this.splitTestService.splitTest.activeSplitTest);
            splitTestValue = snakeCase(this.splitTestService.splitTest.activeSplitTestValue);
        }

        return utmLocalStorage
            ? {
                  ...utmLocalStorage,
                  splitTest,
                  splitTestValue,
              }
            : null;
    }

    // eslint-disable-next-line complexity
    set utm(
        utmStorage: Omit<UtmLocalStorage, 'initiated' | 'referrer' | 'splitTest' | 'splitTestValue'>
    ) {
        const existingUtm = this.utm;

        if (existingUtm) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { initiated, ...existingUtmMinusInitiated } = existingUtm;
            if (!compare(existingUtmMinusInitiated, utmStorage)) {
                this.utilityService.storeObject('utm_storage', {
                    id: utmStorage.id || existingUtm.id,
                    source: utmStorage.source || existingUtm.source,
                    medium: utmStorage.medium || existingUtm.medium,
                    campaign: utmStorage.campaign || existingUtm.campaign,
                    content: utmStorage.content || existingUtm.content,
                    term: utmStorage.term || existingUtm.term,
                    initiated,
                    referrer: existingUtmMinusInitiated.referrer,
                });
            }
        } else {
            this.utilityService.storeObject('utm_storage', {
                ...utmStorage,
                initiated: new Date().toISOString(),
                referrer: this.utilityService.window.document.referrer,
            });
        }

        this.logService.debug(this.utm, '### DEBUG - SETTING UTM: ');
    }

    set utmId(utmId: string | undefined) {
        if (!utmId) {
            return;
        }
        let currentUtm = this.utm;
        if (!currentUtm) {
            return;
        }
        this.utm = {
            ...currentUtm,
            id: utmId,
        };
    }

    clear() {
        this.utilityService.removeObject('utm_storage');
    }
}
