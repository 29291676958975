import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const privacyPolicy: DynamicRouteData = {
    isPublic: true,
    title: 'Privacy Policy - Alpha147',
    metaTags: {
        ...sharedMetaTags,
        description: {
            attribute: 'name',
            value: 'Information and details about the Alpha147 privacy policy.',
        },
        ogDescription: {
            attribute: 'name',
            value: 'Information and details about the Alpha147 privacy policy.',
        },
        ogTitle: {
            attribute: 'name',
            value: 'Privacy Policy',
        },
        ogURL: {
            attribute: 'name',
            value: 'https://alpha147.com/privacy-policy',
        },
        twitterDescription: {
            attribute: 'name',
            value: 'Information and details about the Alpha147 privacy policy.',
        },
        twitterTitle: {
            attribute: 'name',
            value: 'Privacy Policy',
        },
    },
};

export const termsAndConditions: DynamicRouteData = {
    isPublic: true,
    title: 'Terms & Conditions - Alpha147',
    metaTags: {
        ...sharedMetaTags,
        description: {
            attribute: 'name',
            value: 'Terms and conditions for using the Alpha147 website and purchasing pro products on Alpha147.',
        },
        ogDescription: {
            attribute: 'name',
            value: 'Terms and conditions for using the Alpha147 website and purchasing pro products on Alpha147.',
        },
        ogTitle: {
            attribute: 'name',
            value: 'Terms & Conditions',
        },
        ogURL: {
            attribute: 'name',
            value: 'https://alpha147.com/terms-and-conditions',
        },
        twitterDescription: {
            attribute: 'name',
            value: 'Terms and conditions for using the Alpha147 website and purchasing pro products on Alpha147.',
        },
        twitterTitle: {
            attribute: 'name',
            value: 'Terms & Conditions',
        },
    },
};

export const allLegal = {
    privacyPolicy,
    termsAndConditions,
};
