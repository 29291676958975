import { randomFromArray } from '@common/helpers';

export enum SplitTest {
    headerCtaText = 'headerCtaText',
}

// - SPLIT TEST VALUES BEGIN - //
// INFO: Unique for each key in SplitTest

export enum HeaderCtaText {
    getStarted = 'getStarted',
    exploreOptions = 'exploreOptions',
}

// - SPLIT TEST VALUES END - //

type SplitTestValues = {
    headerCtaText: (keyof typeof HeaderCtaText)[];
};

type SplitTestDefault = {
    headerCtaText: keyof typeof HeaderCtaText;
};

type SplitTests = {
    [index in SplitTest]: {
        values: SplitTestValues[index];
        default: SplitTestDefault[index];
    };
};

export const splitTests: SplitTests = {
    headerCtaText: {
        values: ['getStarted', 'exploreOptions'],
        default: 'getStarted',
    },
};

export const randomSplitTest = (splitTestCampaign: SplitTest): string => {
    return randomFromArray(splitTests[splitTestCampaign].values);
};

export interface SplitTestLocalStorage {
    activeSplitTest: SplitTest;
    activeSplitTestValue: string;
}
