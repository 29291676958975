import { Injectable } from '@angular/core';
import type { ImageCompressionOptions } from '@common/models';
import type { FuseResult, IFuseOptions } from 'fuse.js';

import { ScriptService, UtilityService } from '.';

@Injectable()
export class DynamicExternalService {
    constructor(
        private utilityService: UtilityService,
        private scriptService: ScriptService
    ) {}

    async imageCompression(image: File, options: ImageCompressionOptions): Promise<File> {
        await this.scriptService.loadScript('imageCompression');
        return this.utilityService.window.imageCompression(image, options);
    }

    async fuseSearch<T>(
        list: ReadonlyArray<string>,
        searchValue: string,
        options?: IFuseOptions<string>
    ): Promise<FuseResult<T>[]> {
        await this.scriptService.loadScript('fuse');
        return new this.utilityService.window.Fuse(list, options).search(searchValue);
    }
}
