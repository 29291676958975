import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '83e53d905272f889b33300ca69c96824d9a7a512',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.2.0',
    dateFormatted: '2024-11-07--15-54',
    dateISOString: '2024-11-07T15:54:56.387Z',
};
