import type { EmailTrackingStatus } from './email-log';
import type { UserForAuthentication } from './user';

export interface ResetDBPayload {
    seedAlpha147?: boolean;
    seedAll?: boolean;
    seedUsers?: boolean;
    seedPosts?: boolean;
    usersSeedFilter?: UsersSeedFilter;
    justSeed?: boolean;
}

export interface UsersSeedFilter {
    organizationFilters?: string[];
    userFilters?: string[];
}

export interface UserFromEmailPayload {
    email: string;
}

export interface UserFromEmailResponse {
    result: UserForAuthentication;
}

export interface DeleteUserPayload {
    email: string;
}

export interface DeleteUserResponse {
    result: string;
}

export interface TestErrorPayload {
    name: string;
}

export interface TestErrorResponse {
    result: string;
}

export interface CronJobOptionalPolicyFilter {
    policyUuidFilter?: string[];
}

export interface dailyCronPayload extends CronJobOptionalPolicyFilter {
    numberOfDays?: number;
    invoicesDue?: boolean;
    invoicesOverDue?: boolean;
    policiesCanceled?: boolean;
    policiesLapsed?: boolean;
    policiesUploadRequirementsMet?: boolean;
}

export interface dailyCronResponse {
    updatedInvoicesDue?: number;
    updatedInvoicesOverDue?: number;
    updatedInvoicesCanceled?: number;
    updatedPoliciesCanceled?: number;
    updatedPoliciesLapsed?: number;
}

export interface QaGeneratePayload {
    toGenerate: 'TODO';
}

export interface QaGeneratePayloadWithUser {
    firstName: string;
    lastName: string;
    email: string;
}

export interface QaGenerateResponse {}

export enum RunEnvironment {
    local = 'LOCAL',
    develop = 'DEVELOP',
    stage = 'STAGE',
    production = 'PRODUCTION',
}

// Only set in Non-Production Run Environments
export enum RunEnvironmentRole {
    qa = 'QA',
    testing = 'TESTING',
    load = 'LOAD',
    live = 'LIVE',
}

export interface RunEnvironmentFull {
    runEnvironment: RunEnvironment;
    runEnvironmentRole: RunEnvironmentRole;
}

export interface processAutoBillingResponse {
    result: string;
    invoicesPaid: number;
}

export interface CheckEmailStatusPayload {
    dynamicTemplateName: string;
    userEmail: string;
    quoteEnsId?: UUID;
    policyEnsId?: UUID;
}

export interface CheckEmailStatusResponse {
    status: EmailTrackingStatus;
}
