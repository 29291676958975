export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
    return input !== null && input !== undefined;
}

export function distinctValueIDOrNull<T extends { valueID: number }>(
    previous: T | null,
    current: T | null
): boolean {
    if (previous === current) {
        return true;
    }
    if (previous === null || current === null) {
        return false;
    }
    if (previous.valueID === current.valueID) {
        return true;
    }
    return false;
}
