<div class="svg-container mx-3">
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.46 33.6">
        <defs>
            <style>
                .cls-1 {
                    fill: #6f7d95;
                }
                .cls-2 {
                    fill: #b2c1d6;
                }
            </style>
        </defs>
        <g id="Layer_1-2">
            <path class="cls-2" d="M25.2,33.6l-1.39-4.66h-11.57l-1.39,4.66H0L12.05.58h12.34l12.05,33.02h-11.23ZM14.45,21.55h7.15l-3.46-11.76h-.19l-3.5,11.76Z" />
            <path class="cls-2" d="M40.42.58h10.61v24.58h16.94v8.45h-27.55V.58Z" />
            <path class="cls-2" d="M96.43,1.99c1.6.94,2.85,2.24,3.74,3.89.9,1.65,1.34,3.5,1.34,5.54v.62c0,2.05-.45,3.9-1.34,5.57-.9,1.66-2.14,2.97-3.74,3.91s-3.42,1.42-5.47,1.42h-7.92v10.66h-10.61V.58h18.53c2.05,0,3.87.47,5.47,1.42ZM83.04,15.22h4.37c1.12,0,1.96-.29,2.52-.86.56-.58.84-1.36.84-2.35v-.38c0-1.02-.28-1.82-.84-2.38-.56-.56-1.4-.84-2.52-.84h-4.37v6.82Z" />
            <path class="cls-2" d="M129.36,33.6v-12.53h-11.66v12.53h-10.61V.58h10.61v12.05h11.66V.58h10.61v33.02h-10.61Z" />
            <path class="cls-2" d="M169.2,33.6l-1.39-4.66h-11.57l-1.39,4.66h-10.85L156.05.58h12.34l12.05,33.02h-11.23ZM158.45,21.55h7.15l-3.46-11.76h-.19l-3.5,11.76Z" />
            <path class="cls-1" d="M210.38,26.21v7.39h-24.91v-7.39h7.82v-14.93h-7.82v-5.57c2.14-.13,4.54-.7,7.2-1.7,2.66-1.01,4.97-2.17,6.96-3.48h3.22v25.68h7.54Z" />
            <path class="cls-1" d="M229.87,6.22c-1.31,2.48-2.87,4.96-4.68,7.44-1.81,2.48-3.5,4.52-5.06,6.12h9.5v-6.86c.99-1.41,2-3.07,3.02-4.99,1.02-1.92,1.7-3.5,2.02-4.75h4.08v16.61h4.61v7.06h-4.61v6.77h-9.12v-6.77h-16.08v-7.15c1.73-2.5,3.44-5.52,5.14-9.07,1.7-3.55,3.05-7.09,4.08-10.61h9.41c-.22,1.66-.99,3.74-2.3,6.22Z" />
            <path class="cls-1" d="M265.85,17.9c-2.1,4.13-3.14,8.42-3.14,12.86v2.83h-10.66c0-4.35.94-8.63,2.81-12.84,1.87-4.21,4.41-8.18,7.61-11.93h-15.84V.58h27.84v4.37c-3.65,4.51-6.52,8.83-8.62,12.96Z" />
        </g>
    </svg>
    <div class="mt-5">
        <svg id="svg-spinner" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <circle cx="24" cy="4" r="4" fill="#fff" />
            <circle cx="12.19" cy="7.86" r="3.7" fill="#5e8df8" />
            <circle cx="5.02" cy="17.68" r="3.4" fill="#5e8df8" />
            <circle cx="5.02" cy="30.32" r="3.1" fill="#5e8df8" />
            <circle cx="12.19" cy="40.14" r="2.8" fill="#5e8df8" />
            <circle cx="24" cy="44" r="2.5" fill="#5e8df8" />
            <circle cx="35.81" cy="40.14" r="2.2" fill="#5e8df8" />
            <circle cx="42.98" cy="30.32" r="1.9" fill="#5e8df8" />
            <circle cx="42.98" cy="17.68" r="1.6" fill="#5e8df8" />
            <circle cx="35.81" cy="7.86" r="1.3" fill="#5e8df8" />
        </svg>
    </div>
</div>
