import {
    arrowLeft,
    boxArrowInLeft,
    boxArrowRight,
    cardImage,
    caretUpFill,
    chevronContract,
    chevronDown,
    chevronRight,
    clipboard2Check,
    download,
    exclamationTriangleFill,
    facebook,
    fileImage,
    fileText,
    github,
    globe,
    google,
    infoCircle,
    keyFill,
    list,
    newspaper,
    people,
    person,
    personPlus,
    plus,
    save,
    search,
    shieldCheck,
    shieldShaded,
    speedometer,
    trash,
    truckFront,
    twitter,
    xCircleFill,
} from 'ngx-bootstrap-icons';

export const bootstrapIcons = {
    arrowLeft,
    boxArrowInLeft,
    boxArrowRight,
    cardImage,
    caretUpFill,
    chevronContract,
    chevronDown,
    chevronRight,
    clipboard2Check,
    download,
    exclamationTriangleFill,
    facebook,
    fileImage,
    fileText,
    github,
    globe,
    google,
    infoCircle,
    keyFill,
    list,
    newspaper,
    people,
    person,
    personPlus,
    plus,
    save,
    search,
    shieldCheck,
    shieldShaded,
    speedometer,
    trash,
    truckFront,
    twitter,
    xCircleFill,
};
