import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const stageTestEnvConfig: EnvConfig = {
    facebookClientID: 'asdqwe',
    githubClientID: 'asdqwe',
    googleClientID: 'asdqwe-asdqwe.apps.googleusercontent.com',
    recaptchaSiteKey: '6LevH3QqAAAAAHSZHJOCxho8yJncekY3MsVnUbGc',
    googlePlacesAPIKey: 'asdqwe-O4',
    stripePublishableKey: 'pk_live_asdqwe',
    backendURL: 'https://stage-test-api.alpha147.com',
    backendWSURL: 'wss://stage-test-api.alpha147.com',
    frontendURL: 'https://stage-test.alpha147.com',
    logLevel: logLevelValues['info'],
};
