import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment } from '@common/models';
import { buildInfo } from '@data/build-info.data';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

_initSentry();

if (environment.production) {
    enableProdMode();
}

function bootstrap() {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}

// eslint-disable-next-line complexity
function _initSentry() {
    let release!: string;
    let tracePropagationTargets!: (string | RegExp)[];
    let tracesSampleRate: number = 1.0;
    let replaysSessionSampleRate: number = 1.0;

    const first8SHA = buildInfo.commitSHA.slice(0, 8);

    switch (buildInfo.deployEnv) {
        case Environment.local:
            // INFO: NOT USING SENTRY IN LOCAL. HERE FOR DEBUG
            release = `alpha147-frontend-angular__local@${buildInfo.version}+${first8SHA}`;
            tracePropagationTargets = ['localhost'];

            break;
        case Environment.develop:
        case Environment['develop-test']:
            release = `alpha147-frontend-angular__develop@${buildInfo.version}+${first8SHA}`;
            tracePropagationTargets = [
                /^https:\/\/develop-api\.alpha147\.com\/api/,
                /^https:\/\/develop-test-api\.alpha147\.com\/api/,
            ];
            break;
        case Environment.stage:
        case Environment['stage-test']:
            release = `alpha147-frontend-angular__stage@${buildInfo.version}+${first8SHA}`;
            tracePropagationTargets = [
                /^https:\/\/stage-api\.alpha147\.com\/api/,
                /^https:\/\/stage-test-api\.alpha147\.com\/api/,
            ];
            break;
        case Environment.prod:
            release = `alpha147-frontend-angular@${buildInfo.version}+${first8SHA}`;
            tracePropagationTargets = [/^https:\/\/api\.alpha147\.com\/api/];
            tracesSampleRate: 0.5;
            replaysSessionSampleRate: 0.5;
            break;
    }

    // if (false) {
    if (buildInfo.deployEnv === Environment.local) {
        console.log('NOT_INTIALIZING_SENTRY_IN_LOCAL');
    } else {
        Sentry.init({
            release,
            environment: buildInfo.deployEnv,
            dsn: 'https://6ff5b4ebca879103e88f560ec75b3165@o4508237198655488.ingest.us.sentry.io/4508237209927680',
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            // Performance Monitoring
            tracesSampleRate, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets,
            // Session Replay
            replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}
