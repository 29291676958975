export enum RoleName {
    root = 'root',
    admin = 'admin',
    editor = 'editor',
    registered = 'registered',
    observer = 'observer',
    guest = 'guest',
}

export type FindRoleErrorCodes = 'ERROR_FINDING_ROLE' | 'ROLE_NOT_FOUND';
