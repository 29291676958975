export enum DevUtilsModalKind {
    homeBase = 'homeBase',
    personalInfo = 'personalInfo',
    rc2 = 'rc2',
}

export interface ScenarioConfig<T, V> {
    buttonText: string;
    testId: T;
    formValues: V;
}
