import { Injectable } from '@angular/core';
import { marked, MarkedOptions } from 'marked';

import { ScriptService, UtilityService } from '.';

// https://marked.js.org/using_advanced#options
const options: MarkedOptions = {
    // baseUrl: undefined,
    breaks: false,
    gfm: true,
    // headerIds: true,
    // headerPrefix: '',
    // highlight: undefined,
    // langPrefix: 'language-',
    // mangle: true,
    pedantic: false,
    // sanitize: false,
    // sanitizer: undefined,
    silent: false,
    // smartLists: false,
    // smartypants: false,
    // xhtml: false,
};

@Injectable()
export class MarkedService {
    constructor(
        private scriptService: ScriptService,
        private utilityService: UtilityService
    ) {}

    async init(): Promise<typeof marked> {
        await this.scriptService.loadScript('marked');
        return this.utilityService.window.marked;
    }
    async compile(mdString: string): Promise<string> {
        return await this.utilityService.window.marked(mdString, options);
    }
}
