import { AnalyticsService } from './analytics.service';
import { AssertionService } from './assertion.service';
import { AuthUtilsService } from './auth-utils.service';
import { ConfirmationService } from './confirmation.service';
import { ConversationService } from './conversation.service';
import { CookieService } from './cookie.service';
import { DevelopService } from './develop.service';
import { DiffService } from './diff.service';
import { DownloadService } from './download.service';
import { DynamicExternalService } from './dynamic-external.service';
import { EnvService } from './env.service';
import { GeolocationService } from './geolocation.service';
import { HashService } from './hash.service';
import { HotKeysService } from './hot-keys.service';
import { LogService } from './log.service';
import { MarkedService } from './marked.service';
import { MetaService } from './meta.service';
import { OnlineService } from './online.service';
import { OverlayService } from './overlay.service';
import { PlatformService } from './platform.service';
import { PostService } from './post.service';
import { PrismService } from './prism.service';
import { ResizeService } from './resize.service';
import { ScriptService } from './script.service';
import { ScrollService } from './scroll.service';
import { SearchService } from './search.service';
import { SignUpService } from './sign-up.service';
import { SplitTestService } from './split-test.service';
import { StyleService } from './style.service';
import { ToastService } from './toast.service';
import { TypeService } from './type.service';
import { UpdateService } from './update.service';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';
import { UtmService } from './utm.service';

// DO NOT include TableService as a provider for app-common services.
// Provide it in component directly.
export const services = [
    AnalyticsService,
    AssertionService,
    AuthUtilsService,
    ConfirmationService,
    DynamicExternalService,
    EnvService,
    GeolocationService,
    HashService,
    HotKeysService,
    LogService,
    MarkedService,
    MetaService,
    OnlineService,
    OverlayService,
    PlatformService,
    PostService,
    ScriptService,
    ScrollService,
    StyleService,
    ToastService,
    UserService,
    UtilityService,
    TypeService,
    SignUpService,
    SearchService,
    ResizeService,
    DownloadService,
    DevelopService,
    UpdateService,
    ConversationService,
    DiffService,
    CookieService,
    UtmService,
    PrismService,
    SplitTestService,
];

export * from './analytics.service';
export * from './assertion.service';
export * from './auth-utils.service';
export * from './confirmation.service';
export * from './conversation.service';
export * from './cookie.service';
export * from './develop.service';
export * from './diff.service';
export * from './download.service';
export * from './dynamic-external.service';
export * from './env.service';
export * from './geolocation.service';
export * from './hash.service';
export * from './hot-keys.service';
export * from './log.service';
export * from './marked.service';
export * from './meta.service';
export * from './online.service';
export * from './overlay.service';
export * from './platform.service';
export * from './post.service';
export * from './prism.service';
export * from './resize.service';
export * from './script.service';
export * from './scroll.service';
export * from './search.service';
export * from './sign-up.service';
export * from './split-test.service';
export * from './style.service';
export * from './table.service';
export * from './toast.service';
export * from './type.service';
export * from './update.service';
export * from './user.service';
export * from './utility.service';
export * from './utm.service';
