import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppShellComponent } from './app-shell/app-shell.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('modules/site/site-routing.module').then((m) => m.SiteRoutingModule),
    },
    {
        path: 'account',
        loadChildren: () =>
            import('modules/account/account-routing.module').then((m) => m.AccountRoutingModule),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('modules/admin/admin-routing.module').then((m) => m.AdminRoutingModule),
    },
    {
        path: 'splash',
        loadChildren: () =>
            import('modules/splash/splash-routing.module').then((m) => m.SplashRoutingModule),
    },
    {
        path: 'dev',
        loadChildren: () =>
            import('modules/dev/dev-routing.module').then((m) => m.DevRoutingModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
    },
    {
        path: 'shell',
        component: AppShellComponent,
    },
    {
        path: '',
        loadChildren: () =>
            import('modules/app-common/app-common-routing.module').then(
                (m) => m.AppCommonRoutingModule
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('modules/landing-pages/landing-pages-routing.module').then(
                (m) => m.LandingPagesRoutingModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking',
            // enableTracing: true,
        }),
    ],
    exports: [],
})
export class AppRoutingModule {}
