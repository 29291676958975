import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { iconsDictionary } from '@data/icons-dictionary.data';

@Component({
    selector: 'sbf-alpha147-icons',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './alpha147-icons.component.html',
    styleUrls: ['alpha147-icons.component.scss'],
})
export class Alpha147IconsComponent implements OnInit {
    @Input() set name(iconName: string) {
        this.element.nativeElement.innerHTML = iconsDictionary[iconName] || '';
    }
    @Input() width?: string;
    @Input() height?: string;
    @Input() color?: string;

    constructor(private element: ElementRef<HTMLDivElement>) {}
    ngOnInit() {
        this.setHeight();
        this.setWidth();
        this.setColor();
        if (this.height && !this.width) {
            this.injectedSVGElement().style.setProperty('width', 'auto');
        }
        if (this.width && !this.height) {
            this.injectedSVGElement().style.setProperty('height', 'auto');
        }
    }

    setHeight() {
        if (!this.height) {
            return;
        }
        this.injectedSVGElement()?.style.setProperty('height', this.height);
    }
    setWidth() {
        if (!this.width) {
            return;
        }
        this.injectedSVGElement()?.style.setProperty('width', this.width);
    }

    setColor() {
        if (!this.color) {
            return;
        }
        this.injectedSVGElement()
            ?.querySelectorAll('path')
            .forEach((path) => {
                if (!this.color) {
                    return;
                }
                path.style.setProperty('fill', this.color);
            });
    }

    private injectedSVGElement(): SVGElement {
        if (!this.element.nativeElement.firstChild) {
            throw new Error('EXPECTED_SVG_ELEMENT_AS_FIRST_CHILD');
        }
        return this.element.nativeElement.firstChild as SVGElement;
    }
}
