import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const prodEnvConfig: EnvConfig = {
    facebookClientID: 'asdqwe',
    githubClientID: 'asdqwe',
    googleClientID: 'asdqwe-asdqwe.apps.googleusercontent.com',
    recaptchaSiteKey: '6LdoIXQqAAAAALkYW9qVVGNd5HRkM7wJcnfPjlEV',
    googlePlacesAPIKey: 'asdqwe-O4',
    stripePublishableKey: 'pk_live_asdqwe',
    backendURL: 'https://api.alpha147.com',
    backendWSURL: 'wss://api.alpha147.com',
    frontendURL: 'https://alpha147.com',
    logLevel: logLevelValues['warn'],
};
