import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AnalyticsService,
    EnvService,
    HotKeysService,
    LogService,
    OnlineService,
    PlatformService,
    ScriptService,
    UpdateService,
    UtilityService,
} from '@common/services';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    delayedLoadingHasLoaded = false;

    subscription: Subscription = new Subscription();

    constructor(
        // Need to import AnalyticsService here to initialize singleton.
        // That way it will listen to the router on all routes.
        private analyticsService: AnalyticsService,
        private updateService: UpdateService,
        private envService: EnvService,
        private scriptService: ScriptService,
        private utilityService: UtilityService,
        private onlineService: OnlineService,
        private platformService: PlatformService,
        private navigationService: NavigationService,
        private hotKeysService: HotKeysService,
        private logService: LogService
    ) {}
    ngOnInit() {
        this.logService.debug(this.envService.currentEnv, '### DEBUG - APP INIT: ');

        if (this.platformService.isServer) {
            // INFO: We are prerendering, no need to load external.
            return;
        }
        if (!this.utilityService.window.navigator.onLine) {
            // INFO: Offline, no need to load external unless we go back online.
            this.onlineService.showOffLine();
            this.onlineService.backOnline$.subscribe(() => this._initExternal());
            return;
        }

        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.0' }).subscribe(() => {
                this.navigationService.goTo('/', true);
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.1' }).subscribe(() => {
                this.navigationService.goTo('/');
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.2' }).subscribe(() => {
                this.navigationService.goTo('/auth/login');
            })
        );

        this._initExternal();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    _initExternal() {
        this.scriptService.loadScript('grecaptcha');
        setTimeout(() => {
            this.navigationService.shouldLoadDeferredService$().subscribe((shouldLoad) => {
                if (shouldLoad) {
                    this._delayedLoading();
                }
            });
        }, 5000);
    }

    _delayedLoading() {
        if (this.delayedLoadingHasLoaded) {
            return;
        }

        // this.utilityService.window.intercomSettings = {
        //     api_base: 'https://api-iam.intercom.io',
        //     app_id: 'qweasd',
        // };
        // this.delayedLoadingHasLoaded = true;

        // this.scriptService.loadScript('intercom');
    }
}
