import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const localEnvConfig: EnvConfig = {
    facebookClientID: 'asdqwe',
    githubClientID: 'asdqwe',
    googleClientID: 'asdqwe-asdqwe.apps.googleusercontent.com',
    recaptchaSiteKey: '6LevH3QqAAAAAHSZHJOCxho8yJncekY3MsVnUbGc',
    googlePlacesAPIKey: 'asdqwe-O4',
    stripePublishableKey: 'pk_live_asdqwe',
    // backendURL: 'https://192.168.86.31:8147',
    // backendWSURL: 'ws://192.168.86.31:8147',
    // frontendURL: 'https://192.168.86.31:4147',
    backendURL: 'http://localhost:8147',
    backendWSURL: 'ws://localhost:8147',
    frontendURL: 'https://localhost:4147',
    logLevel: logLevelValues['debug'],
};
