import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConversationForResults } from '@backend-types/conversation';
import { CreateMessagePayload } from '@backend-types/message';
import { EnvService } from '@common/services';
import { Observable } from 'rxjs';

@Injectable()
export class ConversationService {
    constructor(
        private http: HttpClient,
        private envService: EnvService
    ) {}

    getConversation$(conversationId: UUID): Observable<ConversationForResults> {
        return this.http.get<ConversationForResults>(
            `${this.envService.config.backendURL}/api/latest/TODO/conversation/${conversationId}`
        );
    }

    sendMessage$(createMessagePayload: CreateMessagePayload): Observable<ConversationForResults> {
        return this.http.post<ConversationForResults>(
            `${this.envService.config.backendURL}/api/latest/TODO/message`,
            createMessagePayload
        );
    }
}
