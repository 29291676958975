import { Injectable } from '@angular/core';

import { AssertionService } from './assertion.service';

@Injectable()
export class HashService {
    constructor(private assertionService: AssertionService) {}

    hash(stringToHash: string): string {
        var hash = 0,
            i,
            chr;
        if (stringToHash.length === 0) return hash.toString();
        for (i = 0; i < stringToHash.length; i++) {
            chr = stringToHash.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString(16);
    }
}
