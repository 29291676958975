import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const home: DynamicRouteData = {
    isPublic: true,
    title: 'Alpha147',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const contact: DynamicRouteData = {
    isPublic: true,
    title: 'Alpha147 Contact',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const aboutUs: DynamicRouteData = {
    isPublic: true,
    title: 'Alpha147 About Us',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const faq: DynamicRouteData = {
    isPublic: true,
    title: 'Alpha147 FAQ',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const siteConfigs = {
    home,
    contact,
    aboutUs,
    faq,
};
